import Intro from "./Intro";

const Contact = () => {
  return (
    <div>
      <Intro title={"Coming Soon!"} />
    </div>
  );
};

export default Contact;
