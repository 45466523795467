import Intro from "./Intro";

const Photos = () => {
  return (
    <div>
      <Intro title={"Coming Soon!"} />
    </div>
  );
};

export default Photos;
